var a2a_config = window.a2a_config || {};
var $ = require("jquery");
var a2a = {
  appendScript: function (a2a_config) {
    var script = document.createElement('script');
    script.src = "//static.addtoany.com/menu/page.js";
    document.body.appendChild(script);
  },
  init: function () {
    // Configuration
    a2a_config.linkname = 'MamaTato';
    a2a_config.linkurl = 'http://mama-tato-com.ua/';
    a2a_config.num_services = 6;
    a2a_config.show_title = 1;
    a2a_config.icon_color = "#d06981";
    a2a_config.prioritize = ["google_plus", "email", "facebook", "vk", "odnoklassniki"];
    window.a2a_config = a2a_config;
    // Deffered append
    $(this.appendScript.bind(this, a2a_config));
  }
}

module.exports = a2a;