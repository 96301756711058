var $ = require("jquery");
var elWidth = 22 * 5;
var mtCal = {
  addHandlers: function () {
    $(".mt-cal-widget").each(function () {
      var widget = this;
      var visibilityChecker = $(widget).find(".mt-cal-visibility-checker");
      var $parts = $(widget).find(".mt-cal-part");
      $parts.each(function () {
        var calPart = this;
        var currentIndex = 0;
        var calContainer = $(calPart).find(".mt-cal-container:first");
        var calList = $(calContainer).find(".mt-cal-list:first");
        $(calPart).find(".mt-cal-left").on("click", function () {
          var left = parseInt($(calList).css("left")) + elWidth;
          if (left > 0) {
            left = 0;
          }
          $(calList).css("left", left + "px");
          return false;
        });
        $(calPart).find(".mt-cal-right").on("click", function () {
          var left = parseInt($(calList).css("left")) - elWidth;
          var diff = -1 * Math.round(($(calList).width() - $(calContainer).width()));
          if (left < diff) {
            left = diff;
          }
          $(calList).css("left", left + "px");
          return false;
        });
        $(calList).on("click", function () {
          if (!$(calPart).hasClass("mt-cal-selected")) {
            if ($(visibilityChecker).is(":visible")) {
              $parts.each(function () {
                $(this).find(".mt-cal-list:first").css("left", "0px");
              });
            }
            $parts.removeClass("mt-cal-selected");
            $(calPart).addClass("mt-cal-selected");
            return false;
          }
        });
      });
    });
  },
  init: function () {
    // Deffered init
    $(this.addHandlers.bind(this));
  }
}

module.exports = mtCal;