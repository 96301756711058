window.WebFontConfig = {
  google: {
    families: [
      "PT+Serif:400,700:latin,cyrillic",
      "PT+Sans:400,700:latin,cyrillic"
    ]
  }
};
var webfont = {
  init: function () {
    (function(d) {
      var wf = d.createElement('script'), s = d.scripts[0];
      wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.16/webfont.js';
      s.parentNode.insertBefore(wf, s);
   })(document);
  }
}

module.exports = webfont;
